import React from "react";
import Layout from "../../components/Layout";
import About from "../../components/About/About";

const AboutPage = ({ location }) => {
  const lang = "ja";

  // Page setting
  const pageTitle = "About";
  const pageSlug = "about";
  const pageKeywords = [`About`];
  const pageDescription = "箕澤屋の人たちのページです。";
  const pageImage = "";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-sm">{pageTitle}</h1>
      </div>

      <div className="content-area">
        <About />
      </div>
    </Layout>
  );
};

export default AboutPage;
