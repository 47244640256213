import React from "react";
import styles from "./About.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import IntroImage from "../IntroImage";

// import InstagramIcon from "@material-ui/icons/Instagram";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import CreateIcon from "@material-ui/icons/Create";
// import SurroundSoundIcon from "@material-ui/icons/SurroundSound";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      historyImage1: file(relativePath: { eq: "home/img_history_01.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage2: file(relativePath: { eq: "home/img_history_02.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage3: file(relativePath: { eq: "home/img_history_03.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage4: file(relativePath: { eq: "home/img_history_04.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage5_1: file(relativePath: { eq: "home/img_history_05_1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage5_2: file(relativePath: { eq: "home/img_history_05_2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage6: file(relativePath: { eq: "home/img_history_06.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      imageAyana: file(relativePath: { eq: "member/people/ayana.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKobadai: file(relativePath: { eq: "member/people/kobadai.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageGofuku: file(relativePath: { eq: "member/people/gofuku.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShogosamata: file(
        relativePath: { eq: "member/people/shogosamata.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKumafarm: file(relativePath: { eq: "member/people/kumafarm.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageInadaze: file(relativePath: { eq: "member/people/inadaze.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className={styles.root}>
      <section className={`${styles.intro} container-sm`}>
        <div className={styles.intro__section}>
          <p>箕澤屋(みさわや)は、信州伊那谷にある築160年の古民家です。</p>
          <p>
            江戸末期に建てられたこの家は、かつての豪農の屋敷。
            <br className="only-for-medium" />
            立派な梁と囲炉裏が残り、文化的な価値のある貴重な建物です。
          </p>
          <p>
            この家を残したいと願うたくさんの方々の想いをつなぐべく、
            <br className="only-for-medium" />
            約40年間、人の住んでいなかったこの家を改修し、人が集える場所へと整備しました。
          </p>
        </div>

        <div className={styles.intro__section}>
          <h2 className={styles.intro__head}>目指していること</h2>
          <p>
            箕澤屋を維持しながら、関わる人が幸せかつポジティブ、いい刺激を与えられる環境をつくっていきたいと考えています。
          </p>
        </div>

        <div className={styles.intro__section}>
          <h2 className={styles.intro__head}>大切にしていること</h2>
          <p>
            家は、人がいるからこそ、その存在価値があります。いくらすばらしい建物だからといって、人がいなくなったら意味がありません。
          </p>
          <p>
            活動を始めてから、多くの方の助けや協力があってここまで続けてくることができ、人の大切さを身に染みて感じてきました。だからこそ、建物だけではなく、この場所に関わってくれる人を大切にしていきたいと考えています。
          </p>
          <p>
            活動の想いみたいなものはこちらのブログに書いています。
            <br />
            <a
              href="https://blog.misawaya.org/sustainable-preservation/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-color"
            >
              できるだけ長く残していくために
            </a>
          </p>
        </div>
      </section>

      <section className={styles.bg_image}>
        <IntroImage />
      </section>

      <section id="history" className={styles.history}>
        <div className={`${styles.history__inner} container-md`}>
          <div className={styles.period__wrap}>
            <h2 className={styles.history__head}>HISTORY</h2>
            <div className={styles.period__bg}></div>
            <div>
              <div className={`${styles.period}`}>
                <div className={`${styles.period1} ${styles.period__vertical}`}>
                  江戸末期
                </div>
                <div className={styles.period__text}>
                  <div className={styles.period__head}>
                    <div className={styles.head_circle}>
                      <div
                        className={`${styles.circle__outer} ${styles.pointOut}`}
                      ></div>
                      <div className={styles.circle__inner}></div>
                    </div>
                    <span className={styles.head_line}></span>
                    <h4 className={styles.head_copy}>箕澤屋建築</h4>
                  </div>
                  <div className={styles.period__description}>
                    小原家「箕澤屋」が火災により焼失。
                    <br />
                    今の基礎となる建物へと再建築される。
                  </div>
                </div>
                <div className={styles.period__image}>
                  <Img
                    fluid={data.historyImage1.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="箕澤屋建築"
                  />
                </div>
                <div className={styles.period__year}>1861</div>
              </div>

              <div className={`${styles.period}`}>
                <div className={`${styles.period__vertical} ${styles.meiji}`}>
                  明治
                </div>
                <div className={`${styles.period__vertical} ${styles.taisho}`}>
                  大正
                </div>
                <div className={styles.period__text}>
                  <div className={styles.period__head}>
                    <div className={styles.head_circle}>
                      <div
                        className={`${styles.circle__outer} ${styles.pointOut}`}
                      ></div>
                      <div className={styles.circle__inner}></div>
                    </div>
                    <span className={styles.head_line}></span>
                    <h4 className={styles.head_copy}>小原家繁栄時代</h4>
                  </div>
                  <div className={styles.period__description}>
                    江戸〜明治にかけて、さまざまな事業を手掛け資産家となった小原家。明治初期に、地ビール製造なども行うが、規制の影響等により数年で廃業。
                  </div>
                </div>
                <div className={styles.period__image}>
                  <Img
                    fluid={data.historyImage2.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="小原家繁栄時代"
                  />
                </div>
                <div className={styles.period__year}>1900</div>
              </div>

              <div className={`${styles.period}`}>
                <div className={styles.period__vertical}>昭和</div>
                <div className={styles.period__text}>
                  <div className={styles.period__head}>
                    <div className={styles.head_circle}>
                      <div
                        className={`${styles.circle__outer} ${styles.pointOut}`}
                      ></div>
                      <div className={styles.circle__inner}></div>
                    </div>
                    <span className={styles.head_line}></span>
                    <h4 className={styles.head_copy}>無人となる</h4>
                  </div>
                  <div className={styles.period__description}>
                    最後まで家を守ってきた三代前儀十郎の妻、ちか江が病により転居し、箕澤屋は無人となる。
                  </div>
                </div>
                <div className={styles.period__image}>
                  <Img
                    fluid={data.historyImage3.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="無人となる"
                  />
                </div>
                <div className={styles.period__year}>1976</div>
              </div>

              <div className={`${styles.period}`}>
                <div className={styles.period__vertical}>平成</div>
                <div className={styles.period__text}>
                  <div className={styles.period__head}>
                    <div className={styles.head_circle}>
                      <div
                        className={`${styles.circle__outer} ${styles.pointOut}`}
                      ></div>
                      <div className={styles.circle__inner}></div>
                    </div>
                    <span className={styles.head_line}></span>
                    <h4 className={styles.head_copy}>箕澤屋を守る会発足</h4>
                  </div>
                  <div className={styles.period__description}>
                    相続した親族が町に寄贈を申し出たことをきっかけに、保存を望む有志が集まり「信州箕澤屋を守る会」を発足。公開イベント等を実施。
                  </div>
                </div>
                <div className={styles.period__image}>
                  <Img
                    fluid={data.historyImage4.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="箕澤屋を守る会発足"
                  />
                </div>
                <div className={styles.period__year}>1997</div>
              </div>

              <div className={`${styles.period}`}>
                <div className={styles.period__text}>
                  <div className={styles.period__head}>
                    <div className={styles.head_circle}>
                      <div
                        className={`${styles.circle__outer} ${styles.pointOut}`}
                      ></div>
                      <div className={styles.circle__inner}></div>
                    </div>
                    <span className={styles.head_line}></span>
                    <h4 className={styles.head_copy}>現メンバー引継</h4>
                  </div>
                  <div className={styles.period__description}>
                    高齢化した守る会のメンバーから継続困難という話を受け、現メンバーが箕澤屋を引継ぐ。建物維持のため、補修工事を行う。
                  </div>
                </div>
                <div
                  className={`${styles.period__image} ${styles.period__image__1}`}
                >
                  <Img
                    fluid={data.historyImage5_1.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="現メンバー引継1"
                  />
                </div>
                <div
                  className={`${styles.period__image} ${styles.period__image__2}`}
                >
                  <Img
                    fluid={data.historyImage5_2.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="現メンバー引継2"
                  />
                </div>
                <div className={styles.period__year}>2014</div>
              </div>

              <div className={`${styles.period}`}>
                <div className={styles.period__vertical}>令和</div>
                <div className={styles.period__text}>
                  <div className={styles.period__head}>
                    <div className={styles.head_circle}>
                      <div
                        className={`${styles.circle__outer} ${styles.pointOut}`}
                      ></div>
                      <div className={styles.circle__inner}></div>
                    </div>
                    <span className={styles.head_line}></span>
                    <h4 className={styles.head_copy}>新プロジェクト開始</h4>
                  </div>
                  <div className={styles.period__description}>
                    箕澤屋に惹き寄せられ集まった有志メンバーにより、夏季を中心にイベントなどの新たな保存プロジェクトがスタート。現在も不定期で活動中。
                  </div>
                </div>
                <div className={styles.period__image}>
                  <Img
                    fluid={data.historyImage6.childImageSharp.fluid}
                    className={styles.period__image__img}
                    alt="新プロジェクト開始"
                  />
                </div>
                <div className={styles.period__year}>2017</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container-md">
        <section className={styles.people}>
          <h2 className={styles.section__head}>活動メンバー</h2>
          <p className={styles.section__lead}>
            箕澤屋活動を中心に行っているコアメンバーです。
          </p>

          <div className={styles.peopleContainer}>
            <div className={styles.peopleItem}>
              <div className={styles.peopleItem__container}>
                <Img
                  fluid={data.imageAyana.childImageSharp.fluid}
                  alt=""
                  className={styles.peopleItem__image}
                />
                <div>
                  <h3 className={styles.peopleItem__name}>Ayana</h3>
                  <div className={styles.peopleItem__icon}>
                    <a
                      href="https://www.instagram.com/misawayanohanashi/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                    >
                      <InstagramIcon className={styles.icon} />
                    </a>
                    <a
                      href="https://twitter.com/ayaos_eats"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Twitter"
                    >
                      <TwitterIcon className={styles.icon} />
                    </a>
                    <a
                      href="https://blog.misawaya.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Blog"
                    >
                      <CreateIcon className={styles.icon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.peopleItem}>
              <div className={styles.peopleItem__container}>
                <Img
                  fluid={data.imageKobadai.childImageSharp.fluid}
                  alt=""
                  className={styles.peopleItem__image}
                />
                <div>
                  <h3 className={styles.peopleItem__name}>コバダイ</h3>

                  <div className={styles.peopleItem__icon}>
                    <a
                      href="https://www.instagram.com/kobadai_misawaya/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon className={styles.icon} />
                    </a>
                    <a
                      href="https://soundcloud.com/kobadai"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Sound Cloud"
                    >
                      <SurroundSoundIcon className={styles.icon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className={styles.section__head}>Special Thanks</h2>
          <p className={styles.section__lead}>
            箕澤屋は多くの方々に支えられているからこそ保存活動を続けられています。
            <br />
            感謝の意を込めて、ここにお世話になっている方々を紹介します。
          </p>
          <div className={styles.friendsContainer}>
            <div className={styles.friendsItem}>
              <div className={styles.friendsItem__container}>
                <a
                  href="https://www.gofuku.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="gofuku Botanical atelier"
                >
                  <Img
                    fluid={data.imageGofuku.childImageSharp.fluid}
                    alt=""
                    className={styles.friendsItem__image}
                  />
                  <h3 className={styles.friendsItem__name}>
                    gofuku Botanical atelier
                  </h3>
                </a>
              </div>
            </div>

            <div className={styles.friendsItem}>
              <div className={styles.friendsItem__container}>
                <a
                  href="https://smtmus.amebaownd.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <Img
                    fluid={data.imageShogosamata.childImageSharp.fluid}
                    alt=""
                    className={styles.friendsItem__image}
                  />
                  <h3 className={styles.friendsItem__name}>Shogo Samata</h3>
                </a>
              </div>
            </div>

            <div className={styles.friendsItem}>
              <div className={styles.friendsItem__container}>
                <a
                  href="https://kumafarm.weebly.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="熊ファーム"
                >
                  <Img
                    fluid={data.imageKumafarm.childImageSharp.fluid}
                    alt=""
                    className={styles.friendsItem__image}
                  />
                  <h3 className={styles.friendsItem__name}>熊ファーム</h3>
                </a>
              </div>
            </div>

            <div className={styles.friendsItem}>
              <div className={styles.friendsItem__container}>
                <a
                  href="https://www.inadazebrewing.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="In a daze brewing"
                >
                  <Img
                    fluid={data.imageInadaze.childImageSharp.fluid}
                    alt=""
                    className={styles.friendsItem__image}
                  />
                  <h3 className={styles.friendsItem__name}>
                    IN A DAZE BREWING
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div> */}
    </div>
  );
};
